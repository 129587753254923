import Vue from 'vue';
import { MiniCartProps } from './MiniCart.props';

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	name: 'MiniCart',
	props: MiniCartProps,
	data() {
		return {
			isMounted: false,
		};
	},
	mounted() {
		this.isMounted = true;
	},
});
